<script>
    export default {
    name: "TopStrony",
    data() {
        return {};
    },
    props: ["value"],
    computed: {
        LocalVModel: {
            get() {
                return this.value;
            },
            set(wartosc) {
                this.$emit("input", wartosc);
            }
        }
    },
    methods: {
        DoPozycji(Dana) {
            this.LocalVModel.router = Dana
        },
        Domena() {
            let hostname = document.Domena()
            return hostname
        }
    }
}
</script>

<template>
    <div>
        <!--  type="dark" variant="dark" -->
        <!-- <nav class="navbar fixed-top navbar-light bg-light">
        <a class="navbar-brand" href="#">Fixed top</a>
        </nav> -->

        <b-navbar toggleable="lg" class="mt-3">
            <b-navbar-brand  href="/" @click="DoPozycji(['Strona glowna'])">
                <div v-if="Domena() == 'hicad.pl'" >
                    <img src="/images/logoHiCad.png" alt="..." height="40">
                </div>
                <div v-else-if="Domena() == 'adambartoszewicz.pl'" class="font-italic" >
                    <b>Adam Bartoszewicz</b>
                </div>
                <div v-else class="font-italic" >
                    <img src="/images/logoHiCad.png" alt="..." height="40">
                </div>
              </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" class="pl-3" is-nav > 
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="/">Home</b-nav-item>
                    <b-nav-item href="o-mnie">O mnie</b-nav-item>
                    <!-- <b-nav-item href="/">Technologia</b-nav-item> -->
                    <b-nav-item href="doswiadczenie">Doświadczenie</b-nav-item>
                    <b-nav-item href="instrukcje">Instrukcje</b-nav-item>
                    <b-nav-item href="napisali-o-mnie">Napisali o mnie</b-nav-item>
                    <b-nav-item href="kontakt">Kontakt</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <!-- <img src="/images/Kreska.png" style="width: 100%; height: 1px;" alt="Kreska"> -->
        <div class="qqq"></div>
        <!-- {{ LocalVModel }} -->
        <div style=" height: 30px;"></div>
    </div>
</template>

<style>

.qqq {
    /* background-color: rgb(201, 27, 27); */
    background-image: linear-gradient(90deg, rgb(201, 27, 27) 60%, rgb(0, 0, 0) 100%);
    height: 4px;
}


</style>
