<script>
    export default {
        name: "MyCard",
        data() {
            return { 
                'Dane':{
                    'Obraz': '',
                    'Tytul': '',
                    'PodTytul': '',
                    'Text': '',
                    'LinkImg': '',
                    'LinkZewnetrznyText': '',
                    'LinkZewnetrznySciezka': '',
                    'LinkDownloadText': '',
                    'LinkDownloadSciezka': ''
                },
                'Pokaz': false
            }
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/card", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyCard: ', param)
                this.Dane = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <div v-if="Pokaz">
            <div style="margin-bottom: 20px;">
                <div class="card border-1" style="border-bottom: none;">
                    <div class="KreskiGora">
                    </div>
                    <div class="PrzejscieGora">
                    </div>
                    <div>
                        <a v-if="Dane.LinkImg" v-bind:href="Dane.LinkImg">
                            <img class="card-img-top" v-bind:src="Dane.Obraz" v-bind:alt="Dane.Tytul">
                        </a>
                        <img v-else-if="Dane.Obraz" class="card-img-top" v-bind:src="Dane.Obraz" v-bind:alt="Dane.Tytul">
                    </div>
                    <div class="card-body">
                        <h6 v-if="Dane.PodTytul" class="rokmoj">{{ Dane.PodTytul }}</h6>
                        <h5 class="card-title tytulmoj"><b>{{ Dane.Tytul }}</b></h5>
                        <p class="card-text" >{{ Dane.Text }}</p>
                        <!-- Link Zewnętrzny -->
                        <div v-if="Dane.LinkZewnetrznyText && Dane.LinkZewnetrznySciezka" align="right">
                            <h6> 
                                <b-link v-bind:href="Dane.LinkZewnetrznySciezka" target="_blank">{{ Dane.LinkZewnetrznyText }}</b-link>
                            </h6>
                        </div>
                        <!-- Download -->
                        <div v-if="Dane.LinkDownloadText && Dane.LinkDownloadSciezka" align="right">
                            <h6> 
                                <b-link v-bind:href="Dane.LinkDownloadSciezka" download>{{ Dane.LinkDownloadText }}</b-link>
                            </h6>
                        </div>
                    </div>
                    <div class="PrzejscieDola">
                    </div>
                </div>
                <div class="card border-0">
                    <div class="PrzejscieDol">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.rokmoj{
    font-style: italic; 
    text-align: right;
    margin-bottom: 20px;
    background: -webkit-linear-gradient(-60deg, rgb(0, 0, 0) 25%, rgb(0, 4, 255));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.tytulmoj {
    font-style: italic; 
    text-align: right;
    margin-bottom: 20px;
    background: -webkit-linear-gradient(-60deg, rgb(201, 27, 27) 30%, rgb(0, 0, 0));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.KreskiGora {
    /* background-color: black; */
    background-image: linear-gradient(90deg, rgb(201, 27, 27) 60%, rgb(0, 0, 0) 100%);
    height: 2px;
    margin-bottom: 2px;
}
.PrzejscieGora {
    /* background-color: blue; */
    /* border-top: 3px; */
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 15%, rgb(170, 170, 230) 100%);
    height: 60px;
}
.test000Bot {
    /* background-color: black; */
    background-image: linear-gradient(90deg, rgb(255, 255, 255) 30%, rgb(231, 27, 27) 100%);
    height: 1px;
    margin-top: 1px;
}
.PrzejscieDol {
    /* background-color: #0004ff; */
    height: 50px;
    background-image: linear-gradient(0deg, rgb(250, 250, 250) 0%, rgb(230, 230, 255) 100%);
    /* background-image: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(235, 235, 255) 100%); */
    margin-bottom: 0px;
}
.PrzejscieDola {
    /* background-color: #0004ff; */
    height: 25px;
    background-image: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(230, 230, 255) 100%);
    /* background-image: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(235, 235, 255) 100%); */
}
</style>
