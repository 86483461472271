<script>
    import MyParagraf from './MyParagraf.vue'
    import MyJumbotron from './MyJumbotron.vue'
    import MyCardZestaw from './MyCardZestaw.vue'
    import MyYoutube from './MyYoutube.vue'
    import MyCardSimpleZestaw from './MyCardSimpleZestaw.vue'
    import MyCarousel from './MyCarousel.vue'
    import MyWypunktowanieZestaw from './MyWypunktowanieZestaw.vue'

    export default {
        name: "GeneratorStrony",
        data() {
            return { 
                'Dane':{
                    'Sciezka': ''
                },
                'Karty': [],
                Pokaz: false
            }
        },
        components:{
            MyParagraf,
            MyJumbotron,
            MyCardZestaw,
            MyYoutube,
            MyCardSimpleZestaw,
            MyCarousel,
            MyWypunktowanieZestaw
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            /** Tutaj mamy za zadanie potwierdzić tylko czy strona o takim Id Istnieje */
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/strona", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyStronaAPI: ', param)
                this.Dane = param
                // this.Pokaz = true
                this.OdczytajApi_Powiazanie()

            },
            OdczytajApi_Powiazanie(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/stronaPowiazanie", this.PowrotApi_Powiazanie, {'Id': this.Id})
                }
            },
            PowrotApi_Powiazanie(param){
                // console.debug('MyStronaAPI: ', param)
                this.Karty = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <div v-if="Pokaz">
            <!-- {{ Dane }} -->
            <!-- {{ Karty }} -->
            <div v-for="(pozycja, index) in Karty" :key="pozycja.index" v-bind:class="Dane.Klasa">
                <MyJumbotron v-if="Karty[index].Komponent == 'Jumbotron'" v-bind:Id="Karty[index].idKomponent"></MyJumbotron>
                <MyParagraf v-if="Karty[index].Komponent == 'Paragraf'" v-bind:Id="Karty[index].idKomponent"></MyParagraf>
                <MyCardZestaw v-if="Karty[index].Komponent == 'CardZestaw'" v-bind:Id="Karty[index].idKomponent"></MyCardZestaw>
                <MyYoutube v-if="Karty[index].Komponent == 'Youtube'" v-bind:Id="Karty[index].idKomponent"></MyYoutube>
                <MyCardSimpleZestaw v-if="Karty[index].Komponent == 'CardSimpleZestaw'" v-bind:Id="Karty[index].idKomponent"></MyCardSimpleZestaw>
                <MyCarousel v-if="Karty[index].Komponent == 'Carousel'" v-bind:Id="Karty[index].idKomponent"></MyCarousel>
                <MyWypunktowanieZestaw v-if="Karty[index].Komponent == 'WypunktowanieZestaw'" v-bind:Id="Karty[index].idKomponent"></MyWypunktowanieZestaw>
            </div>
        </div>
    </div>
</template>