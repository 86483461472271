<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import { BIconTelephone, BIconEnvelope } from 'bootstrap-vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default {
        name: "MojKontakt",
        data() {
            return { 
                naglowek: {
                    "title":"dane kontaktowe",
                },
                mapa:[
                    {"obraz":"Mapa.jpg"}
                ]
            }
        },
        components:{
            MyJumbotron,
            BIconTelephone,
            BIconEnvelope,
            MyCarousel
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <div  align="center">
            <br>
            <br>
            <div align="center">
                <b-avatar src="./images/zdjecie.jpg"  size="13rem"></b-avatar>
            </div>
            <br>
            <br>
            <h2>Adam Bartoszewicz</h2>
            <br>
            <h4> <BIconTelephone class=" mb-0 mr-2" ></BIconTelephone> telefon </h4>
            <h3> <a href="tel:500523071">500523071</a> </h3>
            <br>
            <h4> <BIconEnvelope class=" mb-0 mr-2"></BIconEnvelope> email </h4>
            <h3> <a href="mailto:paganini@su.home.pl">paganini@su.home.pl</a>  </h3>
            <br>
            <h3> Białystok </h3>
            <br>
        </div>
        <MyCarousel v-bind:mycarousel="mapa"></MyCarousel>
            <br>
            <br>
    </div>
</template>