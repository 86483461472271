<script>
/** 
 * Do wyświetlania po kliknięciu większego zdjęcia można zastosować komponent MODAL
 * https://bootstrap-vue.org/docs/components/embed
*/
    export default{
        name: "MyCarousel",
        data() {
            return { 

            }
        },
        /** 
         * PROPS MYCARD
         * [
         *     {
         *        "obraz":"kabina2.jpg",
         *        "tytul": "proporcje wielkości",
         *        "opis":"wielkość urządzenia w porównaniu z człowiekiem"
         *     },
         *     {
         *        "obraz":"kabina3.jpg",
         *        "tytul": "ściany dotykowe",
         *        "opis":"urządzenie posiada dwie takie ściany dotykowe"
         *     }
         * ]
        */
        props: ["mycarousel"],
        methods:{
            Sciezka(Obraz){
                return "./images/"+Obraz
            }
        }

    }
</script>

<template>
    <div>
        <div class="myCaruselKrTop">
        </div>
        <div class="row justify-content-md-center">
            <div class="col col-sm-12 col-md-10 col-lg-8">
                <!-- id="karuzela" -->
                <b-carousel
                    interval="4000"
                    fade
                    controls
                    indicators
                    style="text-shadow: 1px 1px 2px #333;"
                >
                    <b-carousel-slide v-for="item in mycarousel"
                        v-bind:key="item.id"
                        v-bind:caption="item.tytul"
                        v-bind:text="item.opis"
                        v-bind:img-src="Sciezka(item.obraz)"
                        content-visible-up="md"
                    ></b-carousel-slide>
                </b-carousel>
            </div>
        </div>
        <div class="myCaruselKrBot">
        </div>
    </div>
</template>

<style>
.carousel-control-next,
.carousel-control-prev,
.carousel-label,
.carousel-indicators  {
    filter: invert(1);
}

.myCaruselKrTop {
    background-image: linear-gradient(90deg, rgb(201, 27, 27) 60%, rgb(255, 255, 255) 100%);
    height: 1px;
    width: 60%;
    margin-bottom: 10px;
}

.myCaruselKrBot {
    background-image: linear-gradient(90deg, rgb(255, 255, 255) 60%, rgb(201, 27, 27) 100%);
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>