<script>
    export default {
        name: "MyWypunktowanie",
        data() {
            return {
                'Dane': {
                    'Tekst': '',
                    'Widoczny': 0
                },
                Obraz: `data:image/png;base64,`+`iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAC7npUWHRSYXcgcHJvZmlsZSB0eXBl
IGV4aWYAAHja7ZddkuwmDIXfWUWWgCSExHIwP1XZQZafA7Z7umcmyb1171OqTRmwwEI+n6Bnwvjr
zxn+wEUlc0hqnkvOEVcqqXBFx+N5lV1TTLveV7qG8PxiD48BhknQyvlo9ZpfYdePF+416Hi1B79G
2C9H18DtUNbKjE5/DhJ2Pu10RRLKODu5uD2HevDZtjtk/7jb2K4jXYut5/BsSAaVumKWMA+Bedd+
RiDrTlLRyq4Z80gMfRUJaDB8RQJBXj7vbmN8FuhF5LsXPqv/6H0Sn+tll09a5ksjdL4dIP1e/C3x
08LyiIhfB0xuV19FnrP7nOP8upoyFM1XRsVwq7PewcQDkst+LaMYbkXfdikoHmtsQN5jiwdKo0IM
KjNQok6VJo3dNmoIMfFgQ8vcWLbNxbhwk8UprUKTTYp0ccBqPAJQJuFHLLTXLXu9Ro6VO2EqE5wR
XvnHEv5t8GdKmLMtiSj6QyvExStzEcYit2rMAhCaFzfdAt/lwh+f8gepCoK6ZXZ8YI3H6eJQ+sgt
2ZwF8xTtuYUoWL8cQCKsrQiGBARiJlHKFI3ZiKCjA1BF5NgIfIAAqXJHkJxEcB4ZO6+18Y7RnsvK
mZcZZxNAqGTsJwehClgpKfLHkiOHqoomVc1q6kGL1iw5Zc05W16HXDWxZGrZzNyKVRdPrp7d3L14
LVwEZ6CWXKx4KaVWDhULVfiqmF9hOfiQIx165MMOP8pRG9KnpaYtN2veSqudu3QcEz13695Lr4PC
wEkx0tCRhw0fZdSJXJsy09SZp02fZdYHtYvql/IT1OiixpvUmmcParAGs9sFreNEFzMQ40QgbosA
EpoXs+iUEi9yi1ksjE2hjCB1sQmdFjEgTINYJz3YfZD7IW5B/Ye48X+RCwvd7yAXgO4rt2+o9fU7
1zaxcxcuTaNg92F8eA3sdf2o1V9t347ejt6O3o7ejt6O3o7+B44m/njAP7HhbzlnnjxaqKWQAAAB
g2lDQ1BJQ0MgcHJvZmlsZQAAeJx9kT1Iw0AcxV9TpSIVBTuIdMhQnSyIFnHUKhShQqgVWnUwufQL
mhiSFBdHwbXg4Mdi1cHFWVcHV0EQ/ABxdnBSdJES/5cUWsR4cNyPd/ced+8AoVFlmtU1Dmi6bWZS
STGXXxFDrwhCwAASiMrMMmYlKQ3f8XWPAF/v4jzL/9yfo08tWAwIiMQzzDBt4nXiqU3b4LxPHGFl
WSU+Jx4z6YLEj1xXPH7jXHJZ4JkRM5uZI44Qi6UOVjqYlU2NOEEcUzWd8oWcxyrnLc5atcZa9+Qv
DBf05SWu04wihQUsQoIIBTVUUIWNOK06KRYytJ/08Q+7folcCrkqYOSYxwY0yK4f/A9+d2sVJye8
pHAS6H5xnI8RILQLNOuO833sOM0TIPgMXOlt/0YDmP4kvd7WYkdA/zZwcd3WlD3gcgcYejJkU3al
IE2hWATez+ib8sDgLdC76vXW2sfpA5ClrtI3wMEhMFqi7DWfd/d09vbvmVZ/P3BYcqZ/h/6YAAAA
BmJLR0QA/wAAAAAzJ3zzAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH6AMCEDUamB8ZqAAA
AqVJREFUeNrtm81OU0EUx38dTKAuBBMDsQmJ7AhscIEPUbfGYuJO3bhVNxCje15Ay1JeAV5BY6vB
lSyxbrjSBNnVYFsXcy4O1zaB9n7O8ZecXdN7/2emnTPno0TyTAOrwG1gEbgFzAHXgUn5zC/gGAiA
A+Ar8AVoAD8pIEvAS+ADcAr0R7RT4CPwGljOu+gp4DHQBHpjiB5mPeAz8ESelRuuAs9l+/ZTsh/A
C3l2ptSAVorCo/YdeJCF8Aqwk6HwqO3KO6XCXaCdI/GhteXdEmUD6OZQfGhdecfYmQC2ciw8alvy
zrGJ3y6Q+NC243JCvYDi3Z0w9m++X3DbGOffvuuBA7qjnA6VnB514xyRl4oTdj0S7wZLF2LNQ/Gh
1S5ysWl57IAWUHYFm4gDngLz+Mu8aBx6nw88Xv3QAjef4O6Ah8As/jMrWv+hqWD1Q2tExS8nlMbK
q/Ukb3n2E7gHlNBDSTSfOaCKPqqhJ6YlVLyizAG/gRsGuKNQPKJ51QAr6GXFSLlKK4sGW6vTyoLB
Fiq1MmewVVqtzBj+lqg1MmVQjsE2J2ilY7CdGVo5NpIg0EpgsD05Wjkw2IYkrewbYE+xA/b+X4eB
E2z3lTY+ASdhILSj0AHnSmVLKE2KuqhMi7t3gTeKtv/bgTcjdJTGDhlSGusAmwpWf1O0DsT38vg3
IuXxQdRQ3CCBExf4Jv5SsU4FOPJI/BEjNFNX8adNbuTapw+NkuvjHhtFbpWtx5U4fVdA8bE1SyNf
VC/Yyk8kEUWto3RgIno65PGIbJNip8tNFA9NudyX+DrL1te1rG9XZeBZylfpQJ5ZJkdMAo+ws75J
jc42yeHo7CDC4en3xDM8/YqEhqfTaI68xvnx+QVsV8qMs5Id7Jj8IbZUty8Fm4ak7RPjD0k3yCMQ
WtPNAAAAAElFTkSuQmCC`,
                'Pokaz': false
            }
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/wypunktowanie", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyWypunktowanie: ', param)
                this.Dane = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <div v-if="Pokaz" class="LiniaFlexLeft">
            <div class="Kropki">
                <img class="Kropka" :src="Obraz" alt="">
            </div>
            <div>
                {{ Dane.Tekst }}
            </div>
        </div>
    </div>
</template>

<style scoped>
.Kropka{
    width: 10px;
    height: 10px;
}
.Kropki{
    margin-left: 10px;
    margin-right: 20px;
}
.LiniaFlexLeft {
    /* border: 1px solid red; */
	display: flex;
	align-items: center;
    margin-bottom: 10px;
}
</style>