<script>
    import GeneratorStrony from "@/components/komp/API//GeneratorStrony.vue"
    // Strony, które są inne od automatu lub 
    import StrGlowna from "./StrGlowna.vue"
    // import StrGlowna from "./StrGlowna_new.vue"
    import MojKontakt from "./MojKontakt.vue"

    export default {
        name: "SrodekStrony",
        data() {
            return { 
                Dane: {}
            }
        },
        props: ['value'],
        computed:{
            PathName(){
                return window.location.pathname
            },
            LocalVModel: {
                get(){
                    // console.warn('get')
                    return this.value
                },
                set(wartosc) {
                    // console.warn('set')
                    this.$emit('input', wartosc)
                }
            }
        },
        components:{
            GeneratorStrony,
            StrGlowna,
            MojKontakt,
        },
        methods:{
            OdczytajApi(){
                document.MojeApiGET("/www/SciezkaToID", this.PowrotApi, {'Sciezka': this.PathName})

            },
            PowrotApi(param){
                // console.debug('MyStronaAPI Powrot: ', param)
                this.Dane = param
                // this.Pokaz = true

            },
        },
        created(){
            document.WyslijLokalizacje(this.PathName)
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <GeneratorStrony v-if="Dane.id" v-bind:Id="Dane.id"></GeneratorStrony>
        
        <StrGlowna v-if="PathName == '/'"></StrGlowna>
        <MojKontakt v-if="PathName == '/kontakt'"></MojKontakt>
    </div>
</template>