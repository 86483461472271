<script>
    import MyCard from './MyCard.vue'

    export default {
        name: "MyCardZestaw",
        data() {
            return { 
                'Dane': {
                    'KlasaOrganizacja': '',
                    'Klasa': ''
                },
                'Karty': [],
                'Pokaz': false
            }
        },
        components:{
            MyCard
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/cardZestaw", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyCardZestaw: ', param)
                this.Dane = param
                // this.Pokaz = true
                this.OdczytajApi_ID()
            },
            OdczytajApi_ID(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/cardZestawID", this.PowrotApi_ID, {'Id': this.Id})
                }
            },
            PowrotApi_ID(param){
                // console.debug('MyCardZestaw: ', param)
                this.Karty = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <div v-if="Pokaz">
            <div v-bind:class="Dane.KlasaOrganizacja">
                <div v-for="(pozycja, index) in Karty" :key="pozycja.index" v-bind:class="Dane.Klasa">
                    <MyCard v-bind:Id="Karty[index]"></MyCard>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
