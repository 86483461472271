<script>
    import MyWypunktowanie from './MyWypunktowanie.vue'

    export default {
        name: "MyWypunktowanieZestaw",
        data() {
            return { 
                'Dane': {

                },
                'Karty': [],
                'Pokaz': false
            }
        },
        components:{
            MyWypunktowanie
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/wypunktowanieZestaw", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyCardZestaw: ', param)
                this.Dane = param
                // this.Pokaz = true
                this.OdczytajApi_ID()
            },
            OdczytajApi_ID(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/wypunktowanieZestawID", this.PowrotApi_ID, {'Id': this.Id})
                }
            },
            PowrotApi_ID(param){
                // console.debug('MyCardZestaw: ', param)
                this.Karty = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <!-- {{ Dane }} -->
        <!-- {{ Karty }} -->
        <div v-if="Pokaz">
            <div class="Margines">
                <div v-for="(pozycja, index) in Karty" :key="pozycja.index">
                    <MyWypunktowanie v-bind:Id="Karty[index]"></MyWypunktowanie>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.Margines{
    margin-bottom: 15px;
}
</style>
