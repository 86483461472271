<script>
     export default {
        name: "DolStrony",
        data() {
            return { 

            }
        }
    }
</script>

<template>
    <div>
        <!--  type="dark" variant="dark" -->
        <!-- <img src="/images/Kreska.png" style="width: 100%; height: 1px;" alt="Kreska"> -->
        <div class="eee"></div>
        <b-navbar toggleable="lg">
            <b-navbar-nav>
                <b-nav-text>start &copy; 2010</b-nav-text>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-text>aktualizacja &copy; 2022</b-nav-text>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<style>
    .eee {
        height: 1px;
        background-color: rgb(201, 27, 27);
        margin-top: 30px;
    }
</style>