<script>
    export default {
        name: "MainCiasteczka",
        data() {
            return { 
                ciastko: null
            }
        },
        emits:['onKlik'],
        methods: {
            Klik(){
                this.$emit('onKlik')
            }
        },
        created(){
            this.ciastko = window.location.pathname
        }
    }
</script>

<template>
    <div class="Ciasteczka">
        <!-- {{ ciastko }} -->
        <div>
            Ta witryna używa plików cookie w celu poprawnego jej działania.
        </div>
        <div style="width: 20px;"></div>
        <div>
            <button type="button" class="btn btn-outline-light" v-on:click="Klik()">Rozumiem</button>
        </div>
    </div>
</template>

<style scoped>
.Ciasteczka {
    /* border: 1px solid red; */
    display: flex;  
    align-items: center;
    justify-content: space-around;
    background-color: rgb(77, 77, 77);
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}
</style>