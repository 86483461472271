<script>
    export default {
        name: "MyParagraf",
        data() {
            return {
                'Dane': {
                    'Tekst': ''
                },
                'Pokaz': false
            }
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/paragraf", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyParagraf: ', param)
                this.Dane = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <div v-if="Pokaz">
            <p class="first">{{ Dane.Tekst }}</p>
        </div>
    </div>
</template>

<style scoped>
.first:first-letter {
	/* font-weight: bold; */
	/* font-size: 1.3em; */
    margin-left: 40px;
    /* color:rgb(201, 27, 27); */
}
</style>