<script>
    export default{
        name:"MyJumbotron",
        data() {
            return { 

            }
        },
        /** 
         * PROPS MYJUMBOTRON
         * {
         * "title":"Kabina 3D",
         * "text": "Kabina multimedialna 3D do symulacji i prezentacji pomieszczeń",
         * "rok": "projekt z roku 2012",
         * "dodatek": "urządzenie to zostało opatentowane" 
         * }
        */
        props: ["myjumbotron"]
    }
</script>

<template>
    <div>
        <div class="myJumbKr">
        </div>
        <div  class="myjumb px-3" align="center">
            <h3 style="color: rgb(0, 0, 193); font-style: italic;" v-if="myjumbotron.title"><b>{{ myjumbotron.title }}</b></h3>
            <!-- <h3 style="color: rgb(201, 27, 27);" v-if="myjumbotron.title"><b>{{ myjumbotron.title }}</b></h3> -->
            <h5 v-if="myjumbotron.text">{{ myjumbotron.text }}</h5>
            <!-- <br> -->
            <h6 v-if="myjumbotron.rok" style="color: rgb(201, 27, 27); font-style: italic;" align="left">{{ myjumbotron.rok }}</h6>
            <h6 v-if="myjumbotron.dodatek" align="right">{{ myjumbotron.dodatek }}</h6>
        </div>
        <div class="myJumbKr2">
        </div>
    </div>
</template>

<style scoped>
.myjumb{
    /* border: 1px solid red; */
    background-image: linear-gradient(0deg, rgb(240, 240, 255) 20%, rgb(170, 170, 230) 100%);
    padding-top: 20px;
    padding-bottom: 10px;
    
}

.myJumbKr {
    /* background-color: black; */
    background-image: linear-gradient(90deg, rgb(201, 27, 27) 60%, rgb(0, 0, 0) 100%);
    height: 2px;
    margin-top: 30px;
    margin-bottom: 3px;
}
.myJumbKr2 {
    /* background-color: black; */
    background-image: linear-gradient(90deg, rgb(255, 255, 255) 20%, rgb(201, 27, 27) 100%);
    height: 2px;
    margin-top: 2px;
    margin-bottom: 40px;
}
</style>