<script>
/** 
 * Do wyświetlania po kliknięciu większego zdjęcia można zastosować komponent MODAL
 * https://bootstrap-vue.org/docs/components/embed
*/
    export default{
        name: "MyCarousel",
        data() {
            return { 
                'Dane':{
                    'Interwal': 4000
                },
                'Karty': [],
                'Pokaz': false
            }
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/cardCarousel", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyCarousel: ', param)
                this.Dane = param
                // this.Pokaz = true
                this.OdczytajApi_Obraz()
            },
            OdczytajApi_Obraz(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/cardCarouselObraz", this.PowrotApi_Obraz, {'Id': this.Id})
                }
            },
            PowrotApi_Obraz(param){
                // console.debug('MyCarousel: ', param)
                this.Karty = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }

    }
</script>

<template>
    <div>
        <!-- {{ Dane }} -->
        <!-- {{ Karty }} -->
        <div v-if="Pokaz">
            <div class="myCaruselKrTop">
            </div>
            <div class="row justify-content-md-center">
                <div class="col col-sm-12 col-md-10 col-lg-8">
                    <!-- id="karuzela" -->
                    <b-carousel
                        v-bind:interval="Dane.Interwal"
                        fade
                        controls
                        indicators
                        style="text-shadow: 1px 1px 2px #333;"
                    >
                        <b-carousel-slide v-for="item in Karty"
                            v-bind:key="item.id"
                            v-bind:caption="item.Tytul"
                            v-bind:text="item.TytulUzupelnienie"
                            v-bind:img-src="item.Obraz"
                            content-visible-up="md"
                        ></b-carousel-slide>
                    </b-carousel>
                </div>
            </div>
            <div class="myCaruselKrBot">
            </div>
        </div>
    </div>
</template>

<style>
.carousel-control-next,
.carousel-control-prev,
.carousel-label,
.carousel-indicators  {
    filter: invert(1);
}

.myCaruselKrTop {
    background-image: linear-gradient(90deg, rgb(201, 27, 27) 60%, rgb(255, 255, 255) 100%);
    height: 1px;
    width: 60%;
    margin-bottom: 10px;
}

.myCaruselKrBot {
    background-image: linear-gradient(90deg, rgb(255, 255, 255) 60%, rgb(201, 27, 27) 100%);
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>