<script>
/** 
 * Tutaj Lepszym rozwiązaniem było by zastosowanie komponentu bootstrap EMBED
 * https://bootstrap-vue.org/docs/components/embed
*/
    export default{
        name: "MyYoutube",
        data() {
            return { 
                'Dane':{
                    'Tytul': '',
                    'Zrodlo': ''
                },
                'Pokaz': false
            }
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/youtube", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyCard: ', param)
                this.Dane = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <div v-if="Pokaz">
            <div class="myCaruselKrTop">
            </div>
            <div class="row justify-content-md-center">
                <div class="col col-sm-12 col-md-10 col-lg-8">
                    <div v-if="Dane.Zrodlo" class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" 
                            v-bind:src="Dane.Zrodlo"
                            title="YouTube video player" 
                            frameborder="0" 
                            allowfullscreen>
                        </iframe>
                    </div>
                    <div v-if="Dane.Tytul" class="pt-2">
                        <div align="right"><span class="bolded">{{ Dane.Tytul }}</span></div>
                    </div>
                </div>
            </div>
            <div class="myCaruselKrBot">
            </div>
        </div>
    </div>
</template>

<style>
    .bolded {
        font-weight: bold;
    }
</style>