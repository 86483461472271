<script>
    export default{
        name:"MyJumbotron",
        data() {
            return { 
                'Dane': {
                    'Tytul': '',
                    'Text': '',
                    'Rok': '',
                    'Dodatek': ''
                },
                'Pokaz': false
            }
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/jumbotron", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyJumbotron: ', param)
                this.Dane = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <div v-if="Pokaz">
            <div class="myJumbKr">
            </div>
            <div  class="myjumb px-3" align="center">
                <h3 style="color: rgb(0, 0, 193); font-style: italic;" v-if="Dane.Tytul"><b>{{ Dane.Tytul }}</b></h3>
                <h5 v-if="Dane.Text">{{ Dane.Text }}</h5>
                <h6 v-if="Dane.Rok" style="color: rgb(201, 27, 27); font-style: italic;" align="left">{{ Dane.Rok }}</h6>
                <h6 v-if="Dane.Dodatek" align="right">{{ Dane.Dodatek }}</h6>
            </div>
            <div class="myJumbKr2">
            </div>
        </div>
    </div>
</template>

<style scoped>
.myjumb{
    /* border: 1px solid red; */
    background-image: linear-gradient(0deg, rgb(240, 240, 255) 20%, rgb(170, 170, 230) 100%);
    padding-top: 20px;
    padding-bottom: 10px;
    
}

.myJumbKr {
    /* background-color: black; */
    background-image: linear-gradient(90deg, rgb(201, 27, 27) 60%, rgb(0, 0, 0) 100%);
    height: 2px;
    margin-top: 30px;
    margin-bottom: 3px;
}
.myJumbKr2 {
    /* background-color: black; */
    background-image: linear-gradient(90deg, rgb(255, 255, 255) 20%, rgb(201, 27, 27) 100%);
    height: 2px;
    margin-top: 2px;
    margin-bottom: 40px;
}
</style>